import React, { Component } from 'react'
import Formsy from 'formsy-react'
import { InputFormsy } from 'shared/components/formsy'

import rastImg from 'img/vectores/rast-paq.svg'
import { Row, Col } from 'reactstrap'
import { AButton } from 'shared/components/common'
import { TrackingSearchProps, TrackingSearchState } from './types'

export class TrackingSearch extends Component<TrackingSearchProps, TrackingSearchState> {
  form: any
  state: TrackingSearchState = {}
  onSubmit = (model: any) => {
    if (this.props.onConfirm) {
      this.props.onConfirm(model.code)
    }
    this.form.reset()
  }

  onValidForm = () => {
    this.setState({ canSubmit: true })
  }

  onInvalidForm = () => {
    this.setState({ canSubmit: false })
  }
  render() {
    const { disabled } = this.props
    const { canSubmit } = this.state
    return (
      <Formsy
        ref={(node: any) => {
          this.form = node
        }}
        onValidSubmit={this.onSubmit}
        onValid={this.onValidForm}
        onInvalid={this.onInvalidForm}
      >
        <Row className="rastrear mx-auto" style={{ maxWidth: 730 }}>
          <Col sm={10} xs={12}>
            <h2>Buscar paquete</h2>
          </Col>
          <Col sm={2} xs={12}>
            <img src={rastImg} className="img-responsive" />
          </Col>
          <Col sm={8} xs={12}>
            <InputFormsy
              name="code"
              inputProps={{
                disabled,
                className: 'rastrear-search',
                placeholder: 'Ingresa el folio de rastreo de tu paquete',
                uppercase: true
              }}
              required
            />
          </Col>
          <Col sm={4} xs={12}>
            <AButton variant="med" disabled={!canSubmit || disabled}>
              Rastrear Paquete
            </AButton>
          </Col>
        </Row>
      </Formsy>
    )
  }
}

export default TrackingSearch

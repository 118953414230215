import React, { Component, ReactNode } from 'react'

import './AAutocomplete.css'
import Autocomplete from 'react-autocomplete'
import classNames from 'classnames'
import { AAutocompleteProps, AAutocompleteState } from './types'

class AAutocomplete extends Component<AAutocompleteProps, AAutocompleteState> {
  static getDerivedStateFromProps(nextProps: AAutocompleteProps, prevState: AAutocompleteState) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value }
    }
    return {}
  }
  state: AAutocompleteState = {
    value: '',
    activeSuggestion: 0,
    showSuggestions: false
  }

  handleChange = (ev: any) => {
    let val = ev.currentTarget.value
    const { uppercase, regex, onChange } = this.props
    if (uppercase) {
      val = val.toUpperCase()
    }
    if (regex && !regex.test(val)) {
      val = val.substring(0, val.length - 1)
    }

    if (onChange) {
      onChange(ev, val)
    }

    this.setState({ value: val })
  }

  renderMovieTitle = (state: any, val: any) => {
    return (
      state.zip_code
        .toString()
        .toLowerCase()
        .indexOf(val.toString().toLowerCase()) !== -1
    )
  }

  render(): ReactNode {
    const { wrapperClassName, autocompleteOptions, ...others } = this.props

    const importedProps = { ...others }
    delete importedProps.uppercase

    const { value } = this.state

    return (
      <div className={classNames(`cb-input ${wrapperClassName} autocompleteWrapper`)}>
        {/* <input {...importedProps} value={value} onChange={this.handleChange} /> */}
        <Autocomplete
          getItemValue={(item: any) => item.zip_code}
          items={autocompleteOptions || [{ zip_code: '' }]}
          shouldItemRender={this.renderMovieTitle}
          wrapperStyle={{ width: '100%' }}
          menuStyle={{ zIndex: '9999', position: 'fixed', maxHeight: '200px', overflow: 'auto' }}
          renderItem={(item: any, isHighlighted: any) => (
            <div key={item.id} className="autocompleteDropdown" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
              {item.zip_code} <br /> {item.name}
            </div>
          )}
          renderMenu={(items: any, myValue: any, style: any) => (
            <div
              style={{ ...style, zIndex: '9999', position: 'fixed', maxHeight: '200px', overflow: 'auto' }}
              className="autocompleteDropdown"
            >
              {items.length === 0 && myValue ? 'Código Postal no encontrado. Sin cobertura actualmente para EAD.' : items}
            </div>
          )}
          value={value}
          onChange={this.handleChange}
          onSelect={(val: any) => this.handleChange({ currentTarget: { value: val } })}
        />
      </div>
    )
  }
}

export default AAutocomplete
